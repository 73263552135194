<template>
  <div class="position-relative">
    <div
      v-if="isLoadingData"
      class=" d-flex justify-content-center align-items-center brand-management-spinner"
    >
      <Spinner />
    </div>
    <div
      class="container-fluid"
      style="width: 75em"
    >
      <GenericTabsLayout :tabsNumber="3">
        <template #page-header>
          <div class="card-header d-flex justify-content-between w-100">
            <h5 class=" mt-1 mb-0">
              Brand management
            </h5>
            <h5 class="mt-1 mb-0">
              <input
                id="filter-text-box"
                v-model="searchQuery"
                type="text"
                placeholder="Search..."
              />
            </h5>
          </div>
        </template>
        <template #tab-1-header>
          List
        </template>
        <template #tab-1-content>
          <div
            v-if="getSortedBrands.length"
            class="card-body p-0 w-100 d-flex justify-content-center"
            style="height: 50em;"
          >
            <ag-grid-vue
              :columnDefs="brandsTableColumnsDef"
              :defaultColDef="brandsTableColumnDefaults"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="filteredItems"
              :getRowStyle="getRowStyle"
              class="ag-theme-alpine h-100 w-100"
              sideBar="filters"
            />
          </div>
        </template>
        <template #tab-2-header>
          Bulk update
        </template>
        <template #tab-2-content>
          <div
            class="container mt-5"
          >
            <div class="row d-flex justify-content-center">
              <div class="col col-6">
                <div class="container mb-5 ">
                  <SelectInput
                    ref="settings-name-select"
                    v-model="setting"
                    class="mapping-name-selector"
                    :errors="v$.setting.$errors"
                    :options="getSettingNames"
                    description="Choose setting"
                    label="Setting name"
                    @blur="v$.setting.$touch"
                  />
                </div>
                <div
                  v-if="bulkUpdateSectionVisible"
                  class="card mb-5"
                >
                  <div class="card-header d-flex justify-content-between">
                    Bulk update
                  </div>
                  <div class="card-body w-100 px-5 position-relative">
                    <div class="upload-container">
                      <h5>Upload setting file here</h5>
                      <form id="upload-invoice-form">
                        <FileUploadInput
                          ref="file-upload-input"
                          description="Put your file here"
                          :acceptedExtensions="acceptedExtensions"
                          :errors="inputFileErrors"
                          @blur="validateInputFile"
                        >
                          <template #label-html>
                            CSV (<span class="text-info">.csv</span>) file:
                          </template>
                        </FileUploadInput>
                      </form>
                    </div>
                  </div>
                  <div
                    v-if="uploadingInProgress"
                    class="position-absolute d-flex align-items-center w-100 justify-content-center h-100 spinner-background"
                  >
                    <Spinner />
                  </div>
                  <div class="card-footer">
                    <div class="float-end">
                      <button
                        class="btn btn-primary"
                        :disabled="isUploadButtonDisabled"
                        @click="uploadFile"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div>
                    <div
                      v-if="serverValidationErrors.length > 0"
                      class="card mt-5"
                    >
                      <div class="card-header d-flex justify-content-between bg-danger">
                        File validation errors
                      </div>
                      <div class="card-body w-100 p-5 bg-opacity-10 bg-danger">
                        <p
                          v-for="(error, index) in serverValidationErrors"
                          :key="index"
                        >
                          <span class="font-weight-bold">{{ index + 1 }}</span>.
                          {{ error }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="downloadSectionVisible"
                  class="card"
                >
                  <div class="card-header d-flex justify-content-between">
                    Download
                  </div>
                  <div class="card-body w-100 px-5 position-relative">
                    <div class="upload-container">
                      <div class="text">
                        The latest version of the setting will be downloaded
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="float-end">
                      <button
                        class="btn btn-primary"
                        @click="downloadFile"
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #tab-3-header>
          Exclusions
        </template>
        <template #tab-3-content>
          <div class="row d-flex justify-content-center">
            <div class="col col-6">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  Exclusions
                  <button
                    v-if="addExclusionBtnVisible"
                    class="btn btn-sm btn-primary"
                    @click="addExclusionClickHandler()"
                  >
                    Add
                  </button>
                </div>
                <div class="extensions-container">
                  <div
                    v-if="brandExclusions.length"
                    class="card-body p-0 w-100 d-flex justify-content-center"
                    style="height: 30em;"
                  >
                    <ag-grid-vue
                      :columnDefs="columnDefsTab3"
                      :defaultColDef="defaultColDefTab3"
                      :pagination="true"
                      :paginationAutoPageSize="true"
                      :rowData="brandExclusions"
                      class="ag-theme-alpine h-100 w-100"
                      sideBar="filters"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </GenericTabsLayout>
    </div>
  </div>
  <BrandExclusionModal
    id="exclusion-modal"
    ref="exclusionModal"
    :viewType="modalViewType"
  />
</template>

<script>

import SelectInput from '@/components/common/SelectInput';
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import Spinner from '@/components/common/Spinner';
import FileUploadInput from '@/components/common/FileUploadInput';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { settingNames, downloadUrls, userPrivileges } from '../constants';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { modalViewTypes } from '@/components/constants';
import BrandExclusionModal from './BrandExclusionModal';
import GenericTabsLayout from '../Layouts/GenericTabsLayout';
import { hasPrivilege } from '@/service/userAccess';

const MAX_FILE_SIZE = 120 * 1024 * 1024; // 120 Megabytes
const statusesHashMap = {
  'NEW': { background: '#ecfdf0' },
  'ACTIVE_MISSING': { background: '#fbe9eb' },
  'ACTIVE_OK': { background: '#ffffff' },
  'INACTIVE': { background: '#e2e2e2' }
};

export default {
  components: {
    GenericTabsLayout,
    AgGridVue,
    Spinner,
    FileUploadInput,
    SelectInput,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer,
    BrandExclusionModal
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isLoadingData: false,
      inputFileErrors: [],
      setting: settingNames.companyType,
      uploadingInProgress: false,
      serverValidationErrors: [],
      modalViewType: null,
      exclusionModal: null,
      searchQuery: '',
    };
  },
  validations() {
    return {
      setting: { required: required },
    };
  },
  computed: {
    ...mapGetters({
      brandsData: 'brandManagement/brandsOverview',
      brandExclusions: 'brandManagement/brandExclusions',
      authToken: 'auth/getToken'
    }),
    bulkUpdateSectionVisible() {
      switch (this.setting) {
        case settingNames.companyType:
          return hasPrivilege(userPrivileges.uploadCompanyTypeMappings);
        case settingNames.slaRules:
          return hasPrivilege(userPrivileges.uploadSlaRuleFile);
        case settingNames.ukgMapping:
          return hasPrivilege(userPrivileges.uploadUkgMapping);
        case settingNames.uphThresholds:
          return hasPrivilege(userPrivileges.uploadBrandUph);
        case settingNames.forecastThresholds:
          return hasPrivilege(userPrivileges.uploadForecastRule);
        default: return false;
      }
    },
    downloadSectionVisible() {
      switch (this.setting) {
        case settingNames.companyType:
          return hasPrivilege(userPrivileges.getCompanyTypeMappingsFile);
        case settingNames.slaRules:
          return hasPrivilege(userPrivileges.getSlaRuleFile);
        case settingNames.ukgMapping:
          return hasPrivilege(userPrivileges.getUkgMappingsFile);
        case settingNames.uphThresholds:
          return hasPrivilege(userPrivileges.getUphRule);
        case settingNames.forecastThresholds:
          return hasPrivilege(userPrivileges.getForecastRuleFile);
        default: return false;
      }
    },
    addExclusionBtnVisible() {
      return hasPrivilege(userPrivileges.createBrandExclusion);
    },
    acceptedExtensions() {
      return '.csv';
    },
    filteredItems() {
      const queryTrim = this.searchQuery.trim();
      if (queryTrim.length < 1) {
        return this.getSortedBrands;
      }
      const query = queryTrim.toLowerCase();
      return this.getSortedBrands.filter((item) => {
        return item.name.toLowerCase().includes(query) ||
          item.code.toLowerCase().includes(query) ||
          item.type.toLowerCase().includes(query);
      });
    },
    getSortedBrands() {
      const customSortOrder = _.keys(statusesHashMap);
      return this.brandsData.slice().sort((a, b) => {
        return customSortOrder.indexOf(a.status) - customSortOrder.indexOf(b.status);
      });
    },
    brandsTableColumnDefaults() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    brandsTableColumnsDef() {
      return [
        {
          headerName: 'Brand',
          field: 'name',
          sortable: true,
          width: 280
        },
        {
          headerName: 'Code',
          field: 'code',
          width: 230,
          sortable: true
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 250,
          sortable: true
        },
        {
          headerName: 'Type',
          field: 'type',
          width: 250,
          sortable: true
        },
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            isDisabled: (rowData) => {
              return rowData.status === 'INACTIVE';
            },
            visibilityCheck: () => {
              return hasPrivilege(userPrivileges.getBrandDetails);
            },
            buttonText: 'Details',
            buttonVariant: 'btn-primary',
            clicked: (brandId) => {
              this.openBrandDetailsClickHandler(brandId);
            }
          },
          width: 100
        },
      ];
    },
    defaultColDefTab3() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefsTab3() {
      return [
        {
          headerName: 'Brand',
          field: 'name',
          sortable: true,
          width: 300
        },
        {
          headerName: 'Code',
          field: 'code',
          width: 155,
          sortable: true
        },
        {
          headerName: '',
          field: 'code',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Edit',
            buttonVariant: 'btn-primary',
            clicked: (code) => {
              this.editExclusionClickHandler(code);
            },
            visibilityCheck: () => {
              return hasPrivilege(userPrivileges.updateBrandExclusion);
            }
          },
          width: 80
        }
      ];
    },
    getSettingNames() {
      return _.map(_.values(settingNames), (type) => ({ key: type, value: type }));
    },
    isUploadButtonDisabled() {
      return this.inputFileErrors.length || !this?.$refs['file-upload-input']?.getFile();
    },
    uploadMethods() {
      return {
        [settingNames.companyType]: this.uploadCompanyTypeMapping,
        [settingNames.ukgMapping]: this.uploadUkgMapping,
        [settingNames.uphThresholds]: this.uploadUphThresholds,
        [settingNames.slaRules]: this.uploadSlaRules,
        [settingNames.forecastThresholds]: this.uploadForecastThresholds
      };
    }
  },
  async mounted() {
    this.isLoadingData = true;
    await this.fetchBrandsOverview();
    await this.fetchBrandExclusions();
    this.isLoadingData = false;
  },
  methods: {
    ...mapActions({
      fetchBrandsOverview: 'brandManagement/fetchBrandsOverview',
      fetchBrandExclusions: 'brandManagement/fetchBrandExclusions',
      uploadForecastThresholds: 'forecast/uploadForecastThresholdFile',
      uploadUphThresholds: 'brandManagement/uploadUphThresholds',
      uploadCompanyTypeMapping: 'companyTypeMapping/uploadCompanyTypeMappingFile',
      uploadUkgMapping: 'ukgMapping/uploadUkgMappingFile',
      uploadSlaRules: 'sla/uploadSlaRules'
    }),
    getRowStyle(params) {
      return statusesHashMap[params.data.status];
    },
    validateInputFile() {
      this.inputFileErrors = [];
      const file = this.$refs['file-upload-input'].getFile();
      if (!file) {
        this.inputFileErrors.push({
          $uid: 'file-is-required-error',
          $message: 'The file is required'
        });
        return;
      }
      const fileExtension = _.last(file.name.split('.'));
      if (this.acceptedExtensions.indexOf(fileExtension) === -1) {
        this.inputFileErrors.push({
          $uid: 'file-extension-is-not-allowed-error',
          $message: 'The file extension is not allowed'
        });
      }
      const fileSize = file.size;
      if (fileSize > MAX_FILE_SIZE) {
        this.inputFileErrors.push({
          $uid: 'file-is-too-big',
          $message: 'The file is too big. Please contact administrator for further instructions.'
        });
      }
    },
    async uploadFile() {
      this.uploadingInProgress = true;
      this.serverValidationErrors = [];
      this.validateInputFile();
      if (this.inputFileErrors.length > 0) {
        this.$toast.error('The file must be valid');
        return;
      }
      const formData = new FormData();
      formData.append('file', this.$refs['file-upload-input'].getFile());
      try {
        await this.uploadMethods[this.setting](formData);
        this.$toast.success('The file uploaded successfully');
        this.$refs['file-upload-input'].reset();
        this.inputFileErrors = [];
      } catch (error) {
        const { errorDetails } = error.cause;
        this.serverValidationErrors = errorDetails;
        this.$toast.error('Please fix the file');
      } finally {
        this.uploadingInProgress = false;
      }
    },
    async downloadFile() {
      try {
        const downloadUrl = downloadUrls[this.setting];
        const res = await fetch(`${process.env.VUE_APP_BASE_URL}${downloadUrl}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Authorization': `Bearer ${this.authToken}`
          }
        });
        if (res?.status !== 200) {
          this.$toast.show('Failed to download the file. Please, try again later.', { type: 'error' });
          return;
        }
        const filename = res.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        const blob = await res.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        console.log(e);
        this.$toast.show('Failed to download the file. Please, try again later.', { type: 'error' });
      }
    },
    addExclusionClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.exclusionModal.initAddModal();
    },
    editExclusionClickHandler(code) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.exclusionModal.initModal(code);
    },
    openBrandDetailsClickHandler(brandId) {
      return this.$router.push(`/brand-management/${brandId}`);
    }
  }
};
</script>
<style>
.brand-management-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
}

.mapping-name-selector label {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}

.extensions-container {
  min-height: 40vh;
}
</style>
