<template>
  <ConfirmationModal
    ref="delete-confirmation-modal"
    :onCancelHandler="onCancelHandler"
    :onConfirmHandler="onConfirmHandler"
  >
    <template #content>
      <div
        class="alert alert-danger"
        role="alert"
      >
        <slot name="alert-message">
          <h6>
            <i class="bi bi-exclamation-triangle" />
            Do you really want to delete this record?
            This operation can not be undone!
            <br />
            <i class="bi bi-exclamation-triangle" />
            The changes you have just made will only
            apply to all new data. Not historical data.
          </h6>
        </slot>
      </div>
    </template>
    <template #confirm-button-text>
      <slot name="confirm-button">
        I agree and Delete
      </slot>
    </template>
  </ConfirmationModal>
</template>
<script>
import ConfirmationModal from '@/components/common/ConfirmationModal';

export default {
  components: {
    ConfirmationModal
  },
  props: [ 'onCancelHandler', 'onConfirmHandler' ],
  data() {
    return {
      confirmationModal: null
    };
  },
  mounted() {
    this.confirmationModal = this.$refs['delete-confirmation-modal'].confirmationModal;
  }
};
</script>
