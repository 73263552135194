<template>
  <div
    id="exclusion-modal"
    ref="exclusionModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.name"
              :errors="v$.item.name.$errors"
              description="Brand name"
              label="Brand name"
              @blur="v$.item.name.$touch"
            />
            <TextInput
              v-model="item.code"
              :errors="v$.item.code.$errors"
              description="Brand code"
              label="Brand code"
              @blur="v$.item.code.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="exclusion-save-confirmation-modal"
    :onCancelHandler="cancelHandler"
    :onConfirmHandler="saveHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The list of exclusions will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="exclusion-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  >
    <template #alert-message>
      Do you really want to delete this exclusion brand?
      This operation can not be undone!
    </template>
  </DeleteConfirmationModal>
</template>

<script>
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import { modalViewTypes } from '@/components/constants';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';

const defaultItem = {
  name: null,
  code: null
};
export default {
  components: {
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmationModalSave: null,
      confirmationModalDelete: null,
      exclusionModal: null,
      initialized: false,
      brandCode: null,
      item: {
        name: null,
        code: null
      }
    };
  },
  validations() {
    return {
      item: {
        name: { required },
        code: { required }
      }
    };
  },
  computed: {
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit exclusion' : 'Add exclusion brand';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isDeleteButtonDisabled() {
      return !this.initialized || this.viewType === modalViewTypes.add;
    }
  },
  async mounted() {
    this.exclusionModal = new Modal(this.$refs['exclusionModal']);
    this.confirmationModalSave = this.$refs['exclusion-save-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['exclusion-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      addNewExclusion: 'brandManagement/addExclusion',
      fetchBrandExclusions: 'brandManagement/fetchBrandExclusions',
      deleteExclusionItem: 'brandManagement/deleteExclusion',
      updateExclusionItem: 'brandManagement/updateExclusion',
      fetchExclusionByCode: 'brandManagement/fetchExclusionByCode'
    }),
    async initModal(brandCode) {
      this.initialized = false;
      this.brandCode = brandCode;
      this.exclusionModal.show();
      const itemToEdit = brandCode ? await this.fetchExclusionByCode(brandCode) : { ...defaultItem };
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    initAddModal() {
      this.exclusionModal.show();
      const itemToAdd = {
        name: null,
        code: null
      };
      this.updateItemData(itemToAdd);
      this.brandCode = null;
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.name = newValue.name;
      this.item.code = newValue.code;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.exclusionModal.hide();
      this.confirmationModalSave.show();
    },
    async saveHandler() {
      const dataToSave = {
        ...this.item
      };
      try {
        if (this.viewType === modalViewTypes.add) {
          await this.addNewExclusion(dataToSave);
          this.$toast.success('Exclusion brand successfully added');
        } else {
          await this.updateExclusionItem({ brandItem: dataToSave, brandCode: this.brandCode });
          this.$toast.success('Exclusion brand successfully updated');
        }
        await this.fetchBrandExclusions();
      } catch (e) {
        this.$toast.error('Failed to save the exclusion.<br/>' + e);
      }
    },
    cancelHandler() {
      this.confirmationModalSave.hide();
    },
    showDeleteConfirmationModalHandler() {
      this.exclusionModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.exclusionModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteExclusionItem(this.item.code);
        await this.fetchBrandExclusions();
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    }
  }
};
</script>

<style scoped>

</style>
