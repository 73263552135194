<template>
  <div class="mb-3">
    <label
      v-if="isLabelVisible"
      class="form-label"
    >
      <slot name="label-html">
        {{ label || '' }}
      </slot>
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <input
      ref="uploaded-file"
      :class="{ 'is-invalid': hasErrors }"
      :readonly="readonly"
      :disabled="disabled"
      class="form-control"
      type="file"
      :accept="acceptedExtensions || ''"
      @blur="e => $emit('blur', e)"
      @change="e => $emit('change', e)"
    />
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import { Tooltip } from 'bootstrap';

export default {
  props: [ 'label', 'description', 'readonly', 'errors', 'acceptedExtensions', 'disabled' ],
  emits: [ 'blur', 'change' ],
  computed: {
    hasErrors() {
      return this.errors?.length > 0;
    },
    isLabelVisible() {
      return !!this.label || !!this.$slots['label-html'];
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  },
  methods: {
    getFile() {
      return this.$refs['uploaded-file']?.files[0];
    },
    reset() {
      this.$refs['uploaded-file'].value = null;
      this.$emit('blur');
    }
  }
};
</script>
