<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col col-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <slot name="page-header" />
          </div>
          <div class="card-body w-100">
            <div>
              <ul
                id="PageTab"
                class="nav nav-tabs"
                role="tablist"
              >
                <template
                  v-for="i in tabsNumber"
                  :key="i"
                >
                  <li
                    class="nav-item"
                    role="presentation"
                  >
                    <button
                      :id="`tab-switch-button-${i}`"
                      :aria-controls="`tab-${i}`"
                      :aria-selected="isFirstTab(i)"
                      :class="isFirstTab(i) ? 'nav-link active' : 'nav-link'"
                      :data-bs-target="`#tab-${i}`"
                      data-bs-toggle="tab"
                      role="tab"
                      type="button"
                    >
                      <slot :name="`tab-${i}-header`">
                        Header tab {{ i }}
                      </slot>
                    </button>
                  </li>
                </template>
              </ul>
              <div
                id="tabContent"
                class="tab-content"
              >
                <template
                  v-for="i in tabsNumber"
                  :key="i"
                >
                  <div
                    :id="`tab-${i}`"
                    :aria-labelledby="`tab-${i}`"
                    :class="isFirstTab(i) ? 'tab-pane fade show active' : 'tab-pane'"
                    role="tabpanel"
                  >
                    <slot :name="`tab-${i}-content`" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenericTabsLayout',
  props: ['tabsNumber'],
  methods: {
    isFirstTab(tabNumber) {
      return tabNumber === 1;
    }
  }
};
</script>

<style scoped>

</style>
